module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://deimos.io","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PMKQ2WR","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"productionOnly":true,"respectDNT":false,"trackingCode":"20190584"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#000a2e","display":"standalone","icon":"src/images/deimos-icon.svg","icon_options":{"purpose":"any maskable"},"name":"Deimos Website","short_name":"deimos.io","start_url":"/","theme_color":"#68caf8","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6013208e8e08b8c55a9898f09a8d9cb4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"autoGenHomeLabel":"Home","crumbLabelUpdates":[{"crumbLabel":"Deimos Blog","pathname":"/post"},{"crumbLabel":"The Team","pathname":"/member"},{"crumbLabel":"Google Workspace","pathname":"/google-workspace"},{"crumbLabel":"Professional Services","pathname":"/professional-services"},{"crumbLabel":"Cloud-Native Site Reliability Engineering","pathname":"/professional-services/cloud-native-site-reliability-engineering"},{"crumbLabel":"Cloud-Native Software Engineering","pathname":"/professional-services/cloud-native-software-engineering"},{"crumbLabel":"Cloud-Native Support Engineering","pathname":"/professional-services/cloud-native-support-engineering"},{"crumbLabel":"Platform Modernisation","pathname":"/professional-services/platform-modernisation"},{"crumbLabel":"Cloud Security","pathname":"/professional-services/cloud-security"}],"exclude":["**/dev-404-page/**","**/404/**","**/404.html"],"excludeOptions":{"separator":"/"},"trailingSlashes":false,"useAutoGen":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
